/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
@import "~animate.css/animate.min.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~@ng-select/ng-select/themes/default.theme.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
input,
textarea,
button {
  font-size: 14px;
  font: 400 1rem "Inter", sans-serif;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}
.vis-custom-time {
  background-color: #799dba;
}

.vis-item.vis-dot {
  cursor: pointer;
  border-width: 6px;
  border-radius: 6px;
  border-color: #ff0040;
}

.vis-item {
  border: transparent;
}

.vis-item.vis-point.vis-selected,
.vis-item.vis-selected {
  background-color: transparent;
}

.vis-item.vis-selected {
  border-color: white;
}

.vis-item.vis-background {
  background-color: rgba(33, 150, 243, 0.6);
}

.vis-item.vis-box {
  transform: translateY(12px) !important;
}

.vis-item .vis-item-content {
  font-size: 13px;
}

.vis-time-axis .vis-text {
  color: #1758f1;
}

.vis-time-axis.vis-foreground {
  background: white;
}

.vis-item.vis-dot.primary {
  border-color: #ff0040;
  // opacity: theme("opacity.60");
}

.vis-item.vis-dot.primary.vis-selected {
  border-color: white;
  opacity: theme("opacity.90");
}

.vis-panel.vis-bottom {
  opacity: theme("opacity.90");
}
